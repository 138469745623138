import { notify } from 'notiwind'

export const useNotification = () => {
    const notification = ({
        type,
        title,
        text,
        timeout = null,
        viewChange = null,
        action = null,
    }) => {
        notify(
            {
                type,
                title,
                text,
                viewChange,
                action,
            },
            timeout || 5000
        )
    }

    /**
     * @param {{title, text, timeout, type, viewChange}} options
     */
    const successNotify = (options) => {
        notification({
            type: 'success',
            ...options,
        })
    }

    /**
     * @param {{title, text, timeout, type, viewChange}} options
     */
    const warningNotify = (options) => {
        notification({
            type: 'warning',
            ...options,
        })
    }

    /**
     * @param {{title, text, timeout, type, viewChange}} options
     */
    const errorNotify = (options) => {
        notification({
            type: 'danger',
            ...options,
        })
    }

    /**
     * @param {{title, text, timeout, type, viewChange}} options
     */
    const primaryNotify = (options) => {
        notification({
            type: 'primary',
            ...options,
        })
    }

    return {
        notification,
        successNotify,
        warningNotify,
        errorNotify,
        primaryNotify,
    }
}
